import React from 'react';
import './MazeButton.css';

function MazeButton(props) {
    return <>
        <button className={"maze " + props.options} onClick={props.onClick}>
            
        </button>
    </>;
}

export default MazeButton;