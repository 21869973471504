import { useRef, useState } from 'react';
import './App.css';
import PanAndZoomMap from './Components/PanAndZoomMap';
import NavigationButton from './Components/NavigationButton';
import Screen from './Components/Screen';
import Splash from './Components/Splash';
import MazeButton from './Components/MazeButton';

function App() {
  const splashRef = useRef();
  const homeRef = useRef();
  const overviewRef = useRef();
  const mapsRef = useRef();
  const buyRef = useRef();
  const authorRef = useRef();
  const mazeConstructionRef = useRef();

  const [mapIsRendering, setMapIsRendering] = useState(false);

  let unloadedResources = 2;
  const markResourceLoaded = () => {
    unloadedResources -= 1;
    if (unloadedResources <= 0) {
      document.getElementById("openingSpinner").style.display = "none";
      splashRef.current.show();
    }
  };

  const HOME_TO_OVERVIEW_ANGLE = Math.PI * 1.1;
  const HOME_TO_BUY_ANGLE = Math.PI * -0.1;
  const HOME_TO_MAPS_ANGLE = Math.PI * 0.8;
  const HOME_TO_AUTHOR_ANGLE = Math.PI * 0.2;

  const MAZE_SOUTHWEST_ANGLE = Math.PI * 0.7;

  return (
    <>
      <div className="App">
        {/* Splash screen */}
        <Screen ref={splashRef}>
          <Splash />
          <NavigationButton onClick={() => {
            const angle = Math.PI / 2;
            homeRef.current.animateIn(angle);
            splashRef.current.animateOut(angle + Math.PI);
          }}
            options="startButtonPlacement fadeIn"
            text="Enter Site" />
          <br />
        </Screen>

        {/* Test screen 1: should be replaced with home screen */}
        <Screen ref={homeRef}>
          <img className="blurb" src={process.env.PUBLIC_URL + "/blurb_4096.png"} />
          <img className="logo" src={process.env.PUBLIC_URL + "/logo192.png"} />

          <p className="stinger">
            <i>Jazei</i>, a gripping new fantasy novel from author Justin Murray, follows Kenlin, Ranja, and
            Klanjad&mdash;the last of the legendary, heroic Jazei lineage. But the overwhelming might and terrifying
            magics of a barbaric invasion will force them to ask&hellip; Is heroism enough? To fight a monstrous foe,
            what must they be prepared to do? And what are they willing to <i>be?</i>
          </p>

          {/* <p className="info">
            Enter the minds of those who carve their wills into reality. Forget what the legends told you; 
            legends are hazy, watered-down fancies softened and sifted through countless retellings. But behind 
            the legends are people — real people, as the Wanderer remembers. They think cunningly. They fight 
            viciously. And when champions fail and heroism isn't enough anymore, they become what anyone 
            sane would fear to be.
          </p>
          <p className="info">
            These people are Jazei. This is their story.
          </p> */}

          <NavigationButton onClick={() => {
            const angle = HOME_TO_OVERVIEW_ANGLE;
            overviewRef.current.animateIn(angle);
            homeRef.current.animateOut(angle + Math.PI);
          }}
            options="storyOverview"
            text="Story Overview" />

          <NavigationButton onClick={() => {
            const angle = HOME_TO_BUY_ANGLE;
            buyRef.current.animateIn(angle);
            homeRef.current.animateOut(angle + Math.PI);
          }}
            options="buy"
            text="Where to Buy" />

          <br />

          <NavigationButton onClick={() => {
            setMapIsRendering(true);
            const angle = HOME_TO_MAPS_ANGLE;
            mapsRef.current.animateIn(angle);
            homeRef.current.animateOut(angle + Math.PI);
          }}
            options="maps"
            text="Maps & Info" />

          <NavigationButton onClick={() => {
            const angle = HOME_TO_AUTHOR_ANGLE;
            authorRef.current.animateIn(angle);
            homeRef.current.animateOut(angle + Math.PI);
          }}
            options="author"
            text="About the Author" />

          {/* <NavigationButton onClick={() => {
            const angle = Math.PI / 2;
            splashRef.current.animateIn(angle);
            homeRef.current.animateOut(angle + Math.PI);
          }} 
          options="startButtonPlacement"
          text="Splash" /> */}
        </Screen>

        {/* Story overview screen */}
        <Screen ref={overviewRef}>
          <div className="blurbDiv">
            <p className="blurb">
              <i>I am Jazei. What does that mean?</i>
            </p>
            <p className="blurb">
              Warriors, magicians, champions, heroes&hellip; For more than an age, legends of the Jazei bloodline have captivated
              storytellers and listeners in every corner of Jranjana. Kenlin grew up hearing these legends, and he loved to
              hear them. But they were never real to him&mdash;until the night he shouts a warning to two strangers, learns of his
              own Jazei heritage, and is violently catapulted into a war of desperation.
            </p>
            <p className="blurb">
              These are dying days. Invaders from across the sea have conquered the west, crushing the people beneath a regime
              of tyranny, ceaseless war, and cultic human sacrifice. What few defending kingdoms remain are exhausted,
              weakening, dying. Even the Jazei lineage is dying: Kenlin, his uncle Klanjad, and his cousin Ranja are the last.
              Wherever they travel, they are hunted by mercenaries and assassins, and their fights for survival leave an
              ever-growing trail of destruction and death.
            </p>
            <p className="blurb">
              And <i>these</i> are Jranjana's champions? Is this heroism? The legends speak of honor and noble prowess, but
              no one truly fights for their lives and walks away clean. Perhaps the legends were never real at all. Perhaps
              heroes and champions are lies. In a losing war on the brink of impending, inevitable defeat,
              perhaps <i>Jazei</i> is really a choice&mdash;not to learn who you are, but to decide what you are willing to be.
            </p>
            <p className="blurb">
              For legends spring equally from champions and monsters. When humanity dies in fire and all its champions fall,
              what will rise to take their place? To win the world when heroes fail, who would be willing to become something
              monstrous?
            </p>
          </div>
          <NavigationButton onClick={() => {
            const angle = HOME_TO_OVERVIEW_ANGLE;
            homeRef.current.animateIn(angle + Math.PI);
            overviewRef.current.animateOut(angle);
          }}
            options="newline"
            text="Back" />
        </Screen>

        {/* Maps screen */}
        <Screen ref={mapsRef}>
          <PanAndZoomMap rendering={mapIsRendering} />
          <NavigationButton onClick={() => {
            setMapIsRendering(false);
            const angle = HOME_TO_MAPS_ANGLE;
            homeRef.current.animateIn(angle + Math.PI);
            mapsRef.current.animateOut(angle);
          }}
            options="newline"
            text="Back" />
          <MazeButton onClick={() => {
            setMapIsRendering(false);
            const angle = MAZE_SOUTHWEST_ANGLE;
            mazeConstructionRef.current.animateIn(angle);
            mapsRef.current.animateOut(angle + Math.PI);
          }}
            options="southwest"
            text="Test" />
        </Screen>

        {/* Buy screen */}
        <Screen ref={buyRef}>
          <NavigationButton onClick={() => {
            // TODO: If there are multiple eBook vendors, animate in their options.
            const url = "https://www.amazon.com/dp/B0DSPK5R38";
            window.open(url, "_blank");
          }}
            options="ebook"
            text="eBook" />

          <NavigationButton onClick={() => {
            const url = "https://www.amazon.com/dp/B0DSQP6Y86";
            window.open(url, "_blank");
          }}
            options="paperback"
            text="Paperback" />

          <NavigationButton onClick={() => {
            console.log("Hardcover")
          }}
            options="hardcover"
            text="Hardcover" />

          <NavigationButton onClick={() => {
            console.log("Audiobook");
          }}
            options="audiobook"
            text="Audiobook" />

            <div className="buyComingSoon">
              <p className="buyComingSoon">
                Coming soon!
              </p>
            </div>

          <NavigationButton onClick={() => {
            const angle = HOME_TO_BUY_ANGLE;
            homeRef.current.animateIn(angle + Math.PI);
            buyRef.current.animateOut(angle);
          }}
            options="startButtonPlacement"
            text="Back" />
        </Screen>

        {/* Author screen */}
        <Screen ref={authorRef}>
          <img className="author" src={process.env.PUBLIC_URL + "/author.jpg"} alt="The author, Justin Murray" />
          <p className="author">
            From stories to software to sonnets to songs, Justin Murray has always enjoyed writing. His work as a software engineer in the
            mixed reality and interactivity space has accorded him some amazing opportunities, writing code for astronauts on the ISS and
            inventing algorithms to parse meaning from human motion; but his true passion has always been for worlds that don't exist and
            the minds, hearts, and wonders that can be found there. {/* Exploring these worlds requires him to wear every hat he 
            owns&mdash;writer, programmer, poet, and artist&mdash;and he wouldn't have it any other way. */}He lives in Las Vegas, where he
            spends his leisure time hiking, singing, and brainstorming how to be the coolest uncle he can to his ever-increasing
            number of nieces.
            {/* Justin Murray has been described as a Renaissance man, but he's not <i>that</i> old: he just likes to wear a lot of hats.
            A writer, an inventor, a singer, a logician, a poet, a programmer, a digital artist&hellip; He's written computer code for 
            astronauts on the ISS, written stories for publication on this website, simulated millions of hectares of terrain, and 
            taught himself a whole spectrum of rendering techniques to make fantasy maps because he literally could not figure out how 
            to hand-draw a mountain. He lives in Las Vegas, where he spends his leisure time hiking, singing karaoke, and brainstorming 
            ways to be the coolest uncle to his ever-increasing number of nieces. */}
            {/* Yet, through all his work, there is one principal to which he overwhelmingly holds: whenever 
            possible, he prefers to work on things that don't actually exist. */}
          </p>
          <NavigationButton onClick={() => {
            const angle = HOME_TO_AUTHOR_ANGLE;
            homeRef.current.animateIn(angle + Math.PI);
            authorRef.current.animateOut(angle);
          }}
            options="authorBack"
            text="Back" />
        </Screen>

        <Screen ref={mazeConstructionRef}>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <p className="blurb">
            <i>The uncharted wastes beckon, but none yet wander there...</i>
          </p>
          <p className="blurb">
            If you've found this message, it means the paths you would walk have yet to be opened. Seek 
            out <b>syntheticmagus</b> and tell him, &#8220;The trail is incomplete: your work in 
            the wasteland is not yet done.&#8221;
          </p>
          <p className="blurb">
            &mdash;J.M.
          </p>
          <NavigationButton onClick={() => {
            const angle = Math.PI / 2;
            splashRef.current.animateIn(angle);
            mazeConstructionRef.current.animateOut(angle + Math.PI);
          }}
            options="startButtonPlacement"
            text="Return" />
        </Screen>

        {/* Resource loaders */}
        <img className="loader" src={process.env.PUBLIC_URL + "/background_2048.jpg"} onLoad={markResourceLoaded} />
        <img className="loader" src={process.env.PUBLIC_URL + "/splash_2048.jpg"} onLoad={markResourceLoaded} />
      </div>
    </>
  );
}

export default App;
